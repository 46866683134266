import React from "react";

import { Container } from "./saveMoneyPage.styles";

const SaveMoneyPage = () => {
  return (
    <Container>
      <h1>SAVE MONEY</h1>
      <h2>ABC Members Save Money on Critical Business Services</h2>
      <p>
        Contact <a href="mailto:bruce@abcgima.org">Bruce Talbot</a> for more
        information
      </p>
      <br />
      <div>
        <h3>Critical Business Services</h3>
        <a
          href="https://www.abc.org/Membership/Member-Discounts/Lowes_2023"
          target="_blank"
        >
          Lowes
        </a>
        <br />
        <a
          href="https://www.abc.org/Membership/Member-Discounts/Verizon-Wireless"
          target="_blank"
        >
          Verizon
        </a>
        <br />
        <a href="https://www.abc.org/fuel" target="_blank">
          Fuel Savings Programs
        </a>
        <br />
        <a
          href="https://www.abc.org/Membership/Member-Discounts/Verizon-Wireless"
          target="_blank"
        >
          SEE COMPLETE LIST OF ABC PROGRAM SAVINGS
        </a>
      </div>
      <br />
      <div>
        <h3>Member Only Insurance Programs</h3>
        <p>
          Workers Comp program through Chesapeake Employers Insurance
          <br />
          <a href="https://www.ceiwc.com/abc" target="_blank">
            Learn More
          </a>
        </p>
      </div>
      <div>
        <p>
          Property & Casualty through Penn National Insurance
          <br />
          <a
            href="https://www.pennnationalinsurance.com/business/group-programs/abc-maryland/"
            target="_blank"
          >
            Learn More
          </a>
        </p>
      </div>
      <div>
        <p>
          Prevailing Wage Solutions
          <br />
          <a href="https://www.contractorsplan.com/" target="_blank">
            Learn More (The Contractors Plan)
          </a>
          <br />
          <a href="https://beneco.com/solutions-medical/" target="_blank">
            Learn More (Beneco)
          </a>
        </p>
      </div>
    </Container>
  );
};

export default SaveMoneyPage;
