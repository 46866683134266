import React, { useEffect } from "react";

import Page from "../../components/page/page.component";

const BestSponsorPage = ({ data, setNewsItem, setNewsToggle }) => {
  useEffect(() => {
    // document.getElementById("newsbutton").addEventListener('click', openMag);
    var elements = document.getElementsByClassName("newsbutton");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", openMag);
    }
    return function cleanup() {
      window.removeEventListener("click", openMag);
    };
  }, []);

  const openMag = () => {
    setNewsItem("magazine");
    setNewsToggle(true);
  };

  return (
    <>
      <Page data={data} />
    </>
  );
};

export default BestSponsorPage;
