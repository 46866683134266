import React from "react";
import dayjs from "dayjs";

import LogoContainer from "../logo-container/logo-container.component";

import logoImage from "../../static/images/abc-logo-new.png";
import ceaLogo from "../../static/images/cea-logo.png";
import facebookLogo from "../../static/images/Facebook.png";
import youtubeLogo from "../../static/images/Youtube.png";
import linkedinLogo from "../../static/images/LinkedIn.png";
import instagramLogo from "../../static/images/Instagram.png";
import twitterLogo from "../../static/images/Twitter.png";
import PJSLogo from "../../static/images/JumpStart LOGO.png";

import {
  FooterWrapper,
  FooterContainer,
  ScrollTopContainer,
  FirstRow,
  SecondRow,
  Facebook,
  Youtube,
  Twitter,
  Instagram,
  LinkedIn,
  Address,
  Copyright,
} from "./footer.styles";

const Footer = ({ intViewportWidth }) => {
  const scrollClick = () => {
    // console.log("scroll clicked")
    // const body = document.getElementById("scrollBody");
    // console.log("body", body);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <FooterWrapper>
      {window.location.pathname !== "/test" ? (
        <>
          <ScrollTopContainer>
            <div onClick={scrollClick}>
              ^<br />
              Back to Top
            </div>
          </ScrollTopContainer>
          <FooterContainer className="safari_only">
            {/* {intViewportWidth < 1100 ? (
              <FirstRow>
                <LogoContainer
                  source={logoImage}
                  alt="ABC Logo"
                  widthValue="400px"
                  heightValue="auto"
                  link="/"
                />
                <LogoContainer
                  source={ceaLogo}
                  alt="ABC Logo"
                  widthValue="280px"
                  heightValue="auto"
                  link="/"
                />
                <Copyright>&copy; {dayjs().year()}</Copyright>
              </FirstRow>
            ) : null} */}
            <LogoContainer
              source={ceaLogo}
              alt="ABC Logo"
              widthValue="280px"
              heightValue="auto"
              link="/"
            />

            <FirstRow>
              <LogoContainer
                source={logoImage}
                alt="ABC Logo"
                widthValue="400px"
                heightValue="auto"
                link="/"
              />
              <Address>
                ABC Greater Baltimore
                <br />
                2101 E. Biddle St. Suite 5000
                <br />
                Baltimore, MD 21213
                <br />
                410-821-0351
                <br />
              </Address>
              <SecondRow>
                <Facebook>
                  <LogoContainer
                    margin="0px"
                    source={facebookLogo}
                    alt="Facebook Logo"
                    widthValue="30px"
                    heightValue="30px"
                    link="http://www.facebook.com/abcgreaterbaltimore"
                    target="_blank"
                  />
                </Facebook>
                <Youtube>
                  <LogoContainer
                    margin="0px"
                    source={youtubeLogo}
                    alt="Youtube Logo"
                    widthValue="30px"
                    heightValue="30px"
                    link="https://www.youtube.com/channel/UC1HN8StFmyDwnMN3Qzk6X8A/videos"
                  />
                </Youtube>
                <Twitter>
                  <LogoContainer
                    margin="0px"
                    source={twitterLogo}
                    alt="Twitter Logo"
                    widthValue="30px"
                    heightValue="30px"
                    link="http://www.twitter.com/abcbaltimore"
                  />
                </Twitter>
                <Instagram>
                  <LogoContainer
                    margin="0px"
                    source={instagramLogo}
                    alt="Instagram Logo"
                    widthValue="30px"
                    heightValue="30px"
                    link="https://www.instagram.com/abcgreaterbaltimore/"
                  />
                </Instagram>
                <LinkedIn>
                  <LogoContainer
                    margin="0px"
                    source={linkedinLogo}
                    alt="LinkedIn Logo"
                    widthValue="30px"
                    heightValue="30px"
                    link="https://www.linkedin.com/company/abcgreaterbaltimore "
                  />
                </LinkedIn>
              </SecondRow>
              <Copyright>&copy; {dayjs().year()}</Copyright>
            </FirstRow>

            <LogoContainer
              source={PJSLogo}
              alt="Project JumpStart Logo"
              widthValue="280px"
              heightValue="auto"
              link="/"
            />
          </FooterContainer>
        </>
      ) : null}
    </FooterWrapper>
  );
};

export default Footer;
