import styled from "@emotion/styled";

export const Container = styled.div`
  text-align: center;
  padding: 60px 0px;

  h1 {
    color: var(--colors-sit-e-mainTheme);
    margin-bottom: 0px;
  }
  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;
